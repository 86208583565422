<template lang="pug">
  b-card.manager-organizations-form.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(v-if="action === 'update'" :tabs='tabs')
    b-card-header.bg-transparent.border-0(v-else)
      h5.m-0
        | {{ $t('activerecord.models.organization.one') }}
    b-form.form-wc.form-organization-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-organization-identity_attributes-username(
              :label="$t('activerecord.attributes.identity.username')"
              label-for='organization-identity_attributes-username'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#organization-identity_attributes-username.form-input-first.shadow-sm.is-required(
                name='identity.username'
                type='text'
                autocomplete='username'
                :placeholder="$t('identity.placeholders.username')"
                :state='formValidationState($v.form.data.attributes.identity_attributes.username)'
                @focus.native='formChange'
                @input='$v.form.data.attributes.identity_attributes.username.$touch'
                v-model='identityAttributes.username'
                aria-describedby='organization-identity_attributes-username-feedback'
                trim)
              wc-forms-if#organization-identity_attributes-username-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.identity_attributes.username'
                :remote="formRemoteInvalidFeedback('identity.username')"
                :validators='{ required: {}, regExp: {}, minLength: {}, maxLength: {}, takenOrNotAllowed: {} }')

            b-form-group#form-organization-name(
              :label="$t('activerecord.attributes.shared.name')"
              label-for='organization-name'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#organization-name.shadow-sm.is-required(
                name='name'
                type='text'
                autocomplete='name'
                :class='formInputClassSize'
                :placeholder="$t('organization.placeholders.name')"
                :state='formValidationState($v.form.data.attributes.name)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.name.$model'
                aria-describedby='organization-name-feedback'
                trim)
              wc-forms-if#organization-name-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.name'
                :remote="formRemoteInvalidFeedback('name')"
                :validators='{ required: {}, minLength: {}, maxLength: {} }')

            b-form-group#form-organization-profile_attributes-description(
              v-if="action === 'create'"
              :label="$t('activerecord.attributes.shared.description')"
              label-for='organization-profile_attributes-description'
              label-cols-md='2'
              label-align-md='right')
              b-form-textarea#organization-profile_attributes-description.form-input-last.shadow-sm(
                name='profile.description'
                :placeholder="$t('organization/profile.placeholders.description')"
                :state='formValidationState($v.form.data.attributes.profile_attributes.description)'
                @focus.native='formChange'
                @input='$v.form.data.attributes.profile_attributes.description.$touch'
                v-model='profileAttributes.description'
                aria-describedby='organization-profile_attributes-description-feedback'
                rows='4'
                max-rows='8'
                trim)
              wc-forms-if#organization-profile_attributes-description-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.profile_attributes.description'
                :remote="formRemoteInvalidFeedback('profile.description')"
                :validators='{ minLength: {}, maxLength: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-organization-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/organizations/shared/tabs'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiOrganizations from '@services/api/manager/organizations'
import { Form, mapNested } from '@common/form'
import { regExp } from '@common/form/validations'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-organizations-form',
  mixins: [Form, Tabs],
  components: {
    WcFormsButtons,
  },
  computed: {
    ...mapNested([
      {
        name: 'identityAttributes',
        default: { data: 'relationships.identity.data', key: 'id', type: 'identity' },
        attributes: 'attributes.identity_attributes',
      },
      {
        name: 'profileAttributes',
        default: { data: 'relationships.profile.data', key: 'id', type: 'profile' },
        attributes: 'attributes.profile_attributes',
      },
    ]),
    organizationUpdateQuery() {
      return this.action === 'update' ? { id: this.organization_id } : {}
    },
    apiParams() {
      return {
        get: [{ id: this.organization_id || 'new' }, {}],
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        update: [
          { id: this.organization_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        uniqueIdentityUsername: [
          {},
          {
            ...this.organizationUpdateQuery,
            term: this.identityAttributes.username,
          },
          { responseErrorRaw: true },
        ],
      }
    },
    formInputClassSize() {
      return `${this.formValidationClass(this.$v.form.data.attributes.size_id)} form-input-${
        this.action === 'create' ? 'middle' : 'last'
      }`
    },
  },
  methods: {
    apiCallback(callback) {
      if (callback === 'submit-success') this.$emit('organization-changed')
    },
  },
  validations() {
    let validations = {
      form: {
        data: {
          attributes: {
            identity_attributes: {
              username: {
                required,
                regExp: regExp(this.$wc.conf.regex.username_browser),
                minLength: minLength(this.$wc.conf.limits.min_username),
                maxLength: maxLength(this.$wc.conf.limits.max_username),
                takenOrNotAllowed: async () => {
                  /* Touch attribute first. */
                  this.identityAttributes.username

                  /* Validate only if. */
                  if (
                    this.validateIf(
                      'identity_attributes.username',
                      ['required', 'regExp', 'minLength', 'maxLength'],
                      { if: 'invalid' }
                    )
                  )
                    return true

                  /* Validate remote. */
                  return await new Promise((resolve) => {
                    clearTimeout(this.form.timer.uniqueIdentityUsername)
                    this.form.timer.uniqueIdentityUsername = setTimeout(resolve, 600)
                  }).then(() => {
                    if (this.apiRequest && this.apiRequest.source) this.apiRequest.source.cancel()
                    this.apiRequest = this.formRequest('uniqueIdentity', 'uniqueIdentityUsername')
                    return this.apiRequest.promise // eslint-disable-line promise/no-nesting
                      .then(() => {
                        return true
                      })
                      .catch((error) => {
                        return error.response && error.response.status === 422 ? false : true
                      })
                  })
                },
                remote: () => this.formRemoteValid('identity.username'),
              },
            },
            name: {
              required,
              minLength: minLength(this.$wc.conf.limits.min_name),
              maxLength: maxLength(this.$wc.conf.limits.max_name),
              remote: () => this.formRemoteValid('name'),
            },
          },
        },
      },
    }
    if (this.action === 'create') {
      validations.form.data.attributes.profile_attributes = {
        description: {
          minLength: minLength(this.$wc.conf.limits.min_description),
          maxLength: maxLength(this.$wc.conf.limits.max_description),
          remote: () => this.formRemoteValid('profile.description'),
        },
      }
    }
    return validations
  },
  data() {
    return {
      apiBase: apiOrganizations,
      apiModel: 'organization',
      organization_id: this.$route.params.organization_id,
    }
  },
}
</script>
